import React, { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import whatsapp from "../../../src/assets/img/WhatsApp_icon.png";
import { Link } from "react-router-dom";
export default function RefferReport() {
  const user_id = localStorage.getItem("userid");
  const devid = localStorage.getItem("dev_id");
  const [isLoading, setIsLoading] = useState(true);
  const [refData, setRefData] = useState({ ref_code: "", ref_by: "" });
  const [updatedRefercode, setUpdatedRefercode] = useState("");
  const [loadingButton, setLoadingButton] = useState(false);
  const [loadButton, setLoadButton] = useState(false);
  const [error, setError] = useState("");
  const [showButton, setShowButton] = useState(false); // New state to control button visibility
  const [apiResponse, setApiResponse] = useState(null);

  useEffect(() => {
    getProfile();
  }, []);

  const getProfile = async () => {
    setLoadButton(false);
    const apiUrl = `${process.env.REACT_APP_API_URL_NODE}user-profile`;
    const requestData = {
      app_id: process.env.REACT_APP_API_ID,
      user_id,
      device_id: devid,
    };

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(requestData),
      });

      const data = await response.json();
      setLoadButton(true);

      if (data.is_login === "0") window.location.href = "/";

      if (data.success === "1") {
        setApiResponse(data.is_bonus);
        setRefData({ ref_code: data.ref_code, ref_by: data.ref_by });
        setShowButton(!data.ref_by); // Show button only if ref_by is empty
      } else {
        console.error("API Error:", data.message);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const updateReferCode = async (event) => {
    event.preventDefault();
    setLoadingButton(true);

    const requestData = { user_id, ref_by: updatedRefercode };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL_NODE}user-update-reffercode`,
        requestData,
        { headers: { "Content-Type": "application/json" } }
      );

      if (response.data.success === "1") {
        Swal.fire(
          "Success",
          "Your refer code has been updated successfully!",
          "success"
        );
        getProfile(); // Refresh the refer code
        setUpdatedRefercode(""); // Clear the input
      } else {
        Swal.fire("Error", response.data.message, "error");
      }
    } catch (error) {
      console.error("Error updating refer code:", error);
      Swal.fire(
        "Error",
        "An error occurred while updating the refer code. Please try again.",
        "error"
      );
    } finally {
      setLoadingButton(false);
    }
  };
  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(refData.ref_code)
      .then(() => {
        Swal.fire(
          "Copied!",
          "Your refer code has been copied to the clipboard.",
          "success"
        );
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
        Swal.fire("Error", "Failed to copy the refer code.", "error");
      });
  };

  return (
    <div>
      {isLoading ? (
        <div className="spinner_wrappers  ">
          <Spinner animation="border " />
        </div>
      ) : (
        <section id="Help" className="margin-bottom-88">
          <div className="container">
            <div className="row">
              <div className="col-md-6 offset-md-3 col-12 mt-2">
                <div className="card refer_whatsapp">
                  <div className="card-body">
                    <div
                      class="alert alert-primary text-center border_custum"
                      role="alert"
                    >
                      अब आप REFERAL CODE से अपने दोस्तो को जोड़ के भी 10 परसेंट
                      पैसा कमा सकते हैं
                    </div>
                    <div className="row refer_field d-flex justify-content-between align-items-center">
                      {/* Display Existing Refer Code */}
                      <div className="align-items-center  col-12">
                        <div className="refercode_new">
                          <div className="refercode d-flex justify-content-between align-items-center gap-2">
                            <h6 className="text-dark redeem mb-0">
                              Refer Code
                            </h6>
                            <div className="d-flex gap-2">
                              <p className="mb-0"> {refData.ref_code}</p>
                              <i
                                class="bi bi-copy"
                                onClick={copyToClipboard}
                              ></i>
                            </div>
                            <div className="whatsappicon">
                              <Link
                                className="w-100"
                                to={`whatsapp://send?text=अब आप REFERAL CODE से अपने दोस्तो को जोड़ के भी 10 परसेंट पैसा कमा सकते हैं मेरा रेफरल कोड  👇  ${refData.ref_code} है https://babaclubs.in`}
                              >
                                <img src={whatsapp} alt="whatsapp" />
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {apiResponse !== 0 ? (
                      <div className="row refer_field d-flex justify-content-between align-items-center">
                        <h6 className="col-4 text-dark redeem mb-0">
                          Refer By
                        </h6>
                        <div className="col-8 d-flex gap-2 align-items-center">
                          <div className="d-flex align-items-center gap-2">
                            <div className="">
                              <input
                                type="text"
                                value={
                                  refData.ref_by
                                    ? refData.ref_by
                                    : updatedRefercode
                                }
                                onChange={(e) =>
                                  setUpdatedRefercode(e.target.value)
                                }
                                placeholder={
                                  refData.ref_by
                                    ? "Enter new refer"
                                    : "Enter refer code"
                                }
                                className="form-control cusutmform"
                              />
                            </div>
                            {loadButton && showButton && (
                              <div className="">
                                <button
                                  type="button"
                                  onClick={updateReferCode}
                                  className="playgames checkicon w-100"
                                  disabled={loadingButton || !updatedRefercode}
                                >
                                  <i class="bi bi-check2"></i>

                                  {loadingButton && (
                                    <Spinner animation="border" />
                                  )}
                                </button>
                              </div>
                            )}

                            {error && <p className="text-danger">{error}</p>}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  {/* <span>
                    अब आप REFERAL CODE से अपने दोस्तो को जोड़ के भी 10 परसेंट
                    पैसा कमा सकते हैं
                  </span> */}
                </div>
                <h5 className="mt-2 text-dark text-center reportingtable">
                  Report
                </h5>
                <table class="table table-striped mt-2">
                  <thead>
                    <tr className="table-info">
                      <th scope="col">#</th>
                      <th scope="col">Market </th>
                      <th scope="col">Amount</th>
                      <th scope="col">Date</th>
                    </tr>
                  </thead>
                  <tbody></tbody>
                </table>
                {/* Input for Updating Refer Code */}
              </div>
            </div>
          </div>
        </section>
      )}
    </div>
  );
}
